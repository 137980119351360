<script>
import Vue from 'vue';
import BaseVue from '@frontend/Base.vue';
import Gen from '../helper/Gen';
import VideoBg from 'vue-videobg'

Vue.component('video-bg', VideoBg)
Vue.component("Header",()=>import("@frontend/part/Header.vue"))
export default {
  extends: BaseVue,
  data() {
		return {
			static1:[],
			static2:[],
			static3:[],
			about1:[],
			about2:[],
			about3:[],
			about4:[],
			product:[],
			area:[],
			dm:[],
			slide:[],
			berita:[],
			video:'',
			featured:{},
		}
	},
	async mounted() {
		await this.ready();
		this.$set(this.$root, 'page', this);
		this.apiGetStatic()
		this.apiGetAbout()
		this.apiGetProduct()
		this.apiGetArea()
		this.apiGetDM()
		this.apiGetSlider()
		this.apiGetRuangBerita()
		
		// if(!Gen.getStorage('warnigPopup')){
		// 	this.modalWarning()
		// }
		
		Vue.component("Header",()=>import("@frontend/part/Header.vue"))
   		if (typeof SEMICOLON != "undefined") SEMICOLON.slider.init()
		setTimeout(() => { 
			$('[data-toggle="tooltip"]').tooltip(); 
			SEMICOLON.documentOnReady.init()
			SEMICOLON.slider.sliderParallax()
			SEMICOLON.widget.carousel()
		}, 300)
		$(document).ready(()=>{
			AOS.init()
		})
		$(document).ready(function () {
			$('[data-toggle="popover"]').popover({
				trigger: 'hover',
				html: true,
				content: function () {
					return '<img class="img-fluid" src="' + $(this).data('img') + '" />';
				},
				title: 'Toolbox'
			})
		});	
		$(document).on('click','.mfp-close',()=>{
			this.$router.push('/')
			this.refreshMeta()
		});

		if(this.$route.params.cat){
			this.apiGetDetailPerusahaan()	
		}
	},
	methods: {
		async getStatic(id){
            return (await Gen.apirest('/static-content', {id:id}, ()=>{})).data
        },
        async apiGetStatic(){
            this.static1 = await this.getStatic(1)
            this.static2 = await this.getStatic(11)
            this.static3 = await this.getStatic(18)
        },
		async getAbout(id){
            return (await Gen.apirest('/image-about', {id:id}, ()=>{})).data
        },
        async apiGetAbout(){
            this.about1 = await this.getAbout(1)
            this.about2 = await this.getAbout(2)
            this.about3 = await this.getAbout(3)
            this.about4 = await this.getAbout(4)
        },
        apiGetProduct(){
            Gen.apirest('/home-product-preview', {}, (err, resp)=>{
                if(err) console.log(err)
                this.product = resp.data
            })
        },
        apiGetArea(){
            Gen.apirest('/home-area-preview', {}, (err, resp)=>{
                if(err) console.log(err)
                this.area = resp.data
              	$('.owl-carousel').owlCarousel('destroy'); 
              	setTimeout(() => { 
					SEMICOLON.documentOnReady.init()
					SEMICOLON.widget.carousel()
				}, 300)
            })
        },
        apiGetSlider(){
            Gen.apirest('/slider', {}, (err, resp)=>{
                if(err) console.log(err)
                this.slide = resp.data
            		this.$root.topProgress.done()
            })
        },
        apiGetDM(){
            Gen.apirest('/home-dm-preview', {}, (err, resp)=>{
                if(err) console.log(err)
                this.dm = resp.data
            })
        },
        apiGetRuangBerita(){
            Gen.apirest('/ruang-berita', {}, (err, resp)=>{
                if(err) console.log(err)
                this.berita = resp.data
                this.featured = resp.featured
            })
        },
        apiGetDetailPerusahaan() {
        	Gen.apirest('/detail-pabrik/' + this.$route.params.cat + '/' + this.$route.params.slug, {}, (err, resp) => {
        		if (err) console.log(err)
        		if (!resp) return
        		this.showDetailPabrik(resp.data)
        		$.magnificPopup.open({
        			items: {
        				src: "#detailPabrik"
        			},
        			type: 'inline',
        			closeOnContentClick: false,
        			closeBtnInside: false,
        			fiexContentPos: true,
        			overflowY: 'scroll',
        		})
        		$('html, body').animate({
        			scrollTop: $('#mapsOffice').offset().top + 300
        		}, 'slow');
        	})
        },
        showDetailPabrik(v){
        	if(!this.$route.params.cat){
						window.history.pushState('home', 'Home', v.mm_slug+'/'+v.mm_prod_slug);
					}
        	// console.log(v)
        	$("title").text(v.mm_seo_title)
	        $("meta[property='og:image']").attr('content', v.photo)
	        $("meta[property='og:title']").attr('content', v.mm_seo_title)
	        $("meta[name='description']").attr('content', v.mm_seo_desc)
	        $("meta[property='og:description']").attr('content', v.mm_seo_desc)
	        $("meta[name='keywords']").attr('content', v.mm_seo_keyword)
	        $("meta[name='author']").attr('content', 'Farmsco')
	        $("meta[property='og:site_name']").attr('content', 'farmsco.co.id')
	        $("meta[property='url']").attr('content', window.location.href)
	        $("link[rel='canonical']").attr('content', window.location.href)

        	$('.pt_name').text(v.mm_title)
        	$('.company_name').text(v.mm_company_name)
        	$('.ac-content').text(v.mm_desc)
        	$('.ac-phone').text(v.mm_phone)
        	$('.ac-email').text(v.mm_email)
        	$('address').text(v.mm_address)

        	if(!v.mm_email && !v.mm_phone){
				$('.area-contact').hide()
        	}
        	if(!v.mm_email){
				$('.item_email').hide()
        	}
        	if(!v.mm_phone){
				$('.item_telpn').hide()
        	}
        	if(!v.mm_link_google){
				$('.link_map').hide()
        	}

        	$('.telpto').attr('href','tel:'+v.mm_phone)
        	$('.mailto').attr('href','mailto:'+v.mm_email)
        	$('.link_map').attr('href',v.mm_link_google)      	
        	$('.image_pb').attr('src',v.photo)
		},
		modalWarning() {
			$.magnificPopup.open({
				items: {
					src: "#warning"
				},
				callbacks: {
			        open: ()=> {
			            $.magnificPopup.instance.close = ()=> {
			                Gen.putStorage('warnigPopup', 1)
			                $.magnificPopup.proto.close.call(this);
			            }
			        }
			    },
				type: 'inline',
				closeOnContentClick: false,
				closeBtnInside: false,
				fiexContentPos: true,
				overflowY: 'scroll',
			})
		}
	},
	watch:{}
};
</script>
<template>
<div Content>
  	<Header></Header>
	  	<section id="slider" class="slider-element slider-parallax full-screen with-header swiper_wrapper clearfix home" data-video-autoplay="true" data-loop="false">
			<div class="slider-parallax-inner">
				<div class="swiper-container swiper-parent">
					<div class="swiper-wrapper">
						<template v-for="(s,k) in slide">
							<div class="swiper-slide dark" v-if="s.ms_type==2" :key="k">
								<div class="container clearfix">
									<div class="slider-caption slider-caption-center">
										<h2 data-aos="fade-up" v-html="s.ms_title"></h2>
										<p class="d-none d-md-block" data-aos="fade-up" 
											data-aos-delay="350" v-html="s.ms_desc"></p>
										<a v-if="s.ms_have_btn=='Y'" :href="s.ms_btn_link" target="_blank" class="btn btn-hero mt-md-3" data-aos="fade-up" 
												data-aos-delay="450">
												{{ s.ms_btn_label }}
										</a>
									</div>
								</div>
								<div class="video-wrap">
									<video playsinline autoplay muted loop :poster="assets('fo_images','slider/pabrik.jpg')">
									    <source :src="s.media" type='video/mp4' />
									</video>
									<div class="video-overlay" style="background-color: rgba(0,0,0,0.4);"></div>
								</div>
							</div>
							<template v-else>
								<div class="swiper-slide dark d-none d-lg-block" :style="'background-image: url(\''+s.media+'\'), linear-gradient(rgba(0, 160, 198, .75), rgba(8, 96, 168, .75));'" :key="k">
									<div class="container clearfix">
										<div class="slider-caption slider-caption-center">
											<h2 data-aos="fade-up"  v-html="s.ms_title"></h2>
											<p class="d-none d-md-block" data-aos="fade-up" 
												data-aos-delay="350">{{s.ms_desc}}</p>
											<a v-if="s.ms_have_btn=='Y'" :href="s.ms_btn_link" target="_blank">
												<button class="btn btn-hero mt-md-3" data-aos="fade-up"
													data-aos-delay="450">
													{{ s.ms_btn_label }}
												</button>
											</a>
										</div>
									</div>
									<div class="video-overlay" style="background-color: rgba(0,0,0,0.4);"></div>
								</div>
								<div class="swiper-slide dark d-block d-md-none" :style="'background-image: url(\''+s.media_small+'\'), linear-gradient(rgba(0, 160, 198, .75), rgba(8, 96, 168, .75));'" :key="k">
									<div class="container clearfix">
										<div class="slider-caption slider-caption-center">
											<h2 data-aos="fade-up"  v-html="s.ms_title"></h2>
											<p class="d-none d-md-block" data-aos="fade-up" 
												data-aos-delay="350">{{s.ms_desc}}</p>
											<a v-if="s.ms_have_btn=='Y'" :href="s.ms_btn_link" target="_blank">
												<button class="btn btn-hero mt-md-3" data-aos="fade-up"
													data-aos-delay="450">
													{{ web.btn_kenali_kami }}
												</button>
											</a>
										</div>
									</div>
									<div class="video-overlay" style="background-color: rgba(0,0,0,0.4);"></div>
								</div>
							</template>
						</template>
					</div>
					<div class="slider-arrow-left"><i class="icon-angle-left"></i></div>
					<div class="slider-arrow-right"><i class="icon-angle-right"></i></div>
				</div>
				<a href="javascript:;" data-scrollto="#content" data-offset="100" class="dark one-page-arrow"><i class="icon-angle-down infinite animated fadeInDown"></i></a>
			</div>
		</section>

		<section id="content">
			<div class="content-wrap">
				<div class="section nobg nobottommargin">
					<div class="circle_wrap">
						<VImg :src="assets('fo_images','circle_logo.png')" alt="Circle Logo Farmsco" title="Circle Logo Farmsco" />
					</div>
					<div class="container">
						<div class="form-row">
							<div class="col-sm-6 col-md-6" data-aos="fade-right">
								<div class="row">
									<div class="col-md-6 col-6 pr-2 pr-md-3">
										<div class="company_ovr_wrapper">
											<VImg v-if="!isMobile" :src="uploader(about1.miia_image, '266x275')" :alt="about1.miia_title" :title="about1.miia_title"></VImg>
											<VImg v-else :src="uploader(about1.miia_image, '157x163')" :alt="about1.miia_title" :title="about1.miia_title"></VImg>
										</div>
										<div class="company_ovr_wrapper">
											<VImg v-if="!isMobile" :src="uploader(about3.miia_image, '266x235')" :alt="about3.miia_title" :title="about3.miia_title"></VImg>
											<VImg v-else :src="uploader(about3.miia_image, '157x138')" :alt="about3.miia_title" :title="about3.miia_title"></VImg>
										</div>
									</div>
									<div class="col-md-6 col-6 pad_left">
										<div class="company_ovr_wrapper">
											<VImg v-if="!isMobile" :src="uploader(about2.miia_image,'278x343')" :alt="about2.miia_title" :title="about2.miia_title"></VImg>
											<VImg v-else :src="uploader(about2.miia_image,'162x201')" :alt="about2.miia_title" :title="about2.miia_title"></VImg>
										</div>
										<div class="company_ovr_wrapper">
											<VImg v-if="!isMobile" :src="uploader(about4.miia_image, '278x168')" :alt="about4.miia_title" :title="about4.miia_title"></VImg>
											<VImg v-else :src="uploader(about4.miia_image, '162x99')" :alt="about4.miia_title" :title="about4.miia_title"></VImg>
										</div>
									</div>
								</div>
							</div>
							<div class="col-sm-6 col-md-6 col-lg-5 about-desc mt-3 mt-md-0 ml_md_0" data-aos="fade-left">
								<div class="heading-block">
									<h3><span>Farmsco</span> Indonesia</h3>
									<span>No. 1 Partner, Everyday with Farmsco</span>
								</div>
								<div class="wrap_about_intro" v-html="static1.msc_desc"></div>
								<div class="col-12 text-center text-md-left">
									<router-link :to="{name:'AboutUs'}" :class="'btn btn-border_red mt-3'">
										{{ web.btn_learn }}
									</router-link>
								</div>
							</div>
						</div>
					</div>
				</div>

				<!-- Product -->
				<div class="section  nobottommargin notopmargin">
					<div class="container" v-if="product.length">
						<div class="heading-block center" data-aos="fade-up">
							<h3 v-html="static2.msc_title"></h3>
							<span v-html="static2.msc_desc"></span>
						</div>
						<div data-autoplay="2000" data-loop="true" data-margin="15" data-stage-padding="50"
							data-pagi="true" data-items-xs="1" data-items-sm="2" data-items-md="2" data-items-lg="3"
							data-items-xl="3"
							class="owl-carousel products-carousel carousel-widget owl-loaded owl-drag">
							<div class="prod_farm_wrap" v-for="(v,k) in product" :key="k">
								<router-link :to="{name:'Product',params:{slug:v.mp_slug}}">
									<VImg v-if="!isMobile" :src="uploader(v.mp_image,'337x224')" :alt="v.mp_name" :title="v.mp_name"></VImg>
									<VImg v-else :src="uploader(v.mp_image,'230x153')" :alt="v.mp_name" :title="v.mp_name"></VImg>
								</router-link>
								<div class="box_prod_name">
									<div class="txt_prod"><h3 v-html="v.mp_name"></h3></div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<section id="mapsOffice" onclick="window.scrollTo(0, 0);" class="section nobg map nobottommargin notopmargin">
					<div class="heading-block center">
						<h3>{{ web.lbl_area_produksi_kami }}</h3>
					</div>
					<div class="container">
						<div class="map-indo">
							<VImg :src="assets('fo_images','indo_map.png')" alt="Peta Indonesia" title="Peta Indonesia"></VImg>
							<a v-for="(v2,k2) in area" :key="k2" class="bullet a1" data-container="body" data-toggle="popover" data-trigger="hover" data-placement="bottom" :data-content="v2.mm_prod_type" :data-original-title="v2.mm_title" :title="v2.mm_title" aria-describedby="popover320813" :style="v2.style_possition" @click="showDetailPabrik(v2)" href="#detailPabrik" data-lightbox="inline"></a>
						</div>
					</div>
				</section>
				<section class="section h_section map nobottommargin notopmargin"
					:style="'background-image:url(\''+uploader(static3.msc_image)+'\');'">
					<div class="container">
						<div class="row">
							<div class="col-lg-6 col-md-7">
								<div class="wrap_tagline" v-html="static3.msc_desc"></div>
							</div>
						</div>
					</div>
				</section>

				<section class="section nobottommargin notopmargin">
					<div class="container">
						<div class="heading-block center">
							<h3>{{ web.lbl_ruang_berita }}</h3>
						</div>
						<div class="row justify-content-center">
							<div class="col-lg-4 col-md-6 col-sm-6" v-if="featured.ap_title">
								<div class="front-news">
									<div class="fn-img">
										<router-link v-if="featured.ap_cat==1" :to="{name:'JurnalDetail',params:{slug:featured.ap_slug}}">
											<VImg v-if="!isMobile" :src="uploader(featured.ap_photo,'360x239')" :alt="featured.ap_title" :title="featured.ap_title"></VImg>
											<VImg v-else :src="uploader(featured.ap_photo,'330x219')" :alt="featured.ap_title" :title="featured.ap_title"></VImg>
										</router-link>
										<router-link v-if="featured.ap_cat==2" :to="{name:'ArticleDetail',params:{slug:featured.ap_slug}}">
											<VImg v-if="!isMobile" :src="uploader(featured.ap_photo,'360x239')" :alt="featured.ap_title" :title="featured.ap_title"></VImg>
											<VImg v-else :src="uploader(featured.ap_photo,'330x219')" :alt="featured.ap_title" :title="featured.ap_title"></VImg>
										</router-link>
									</div>
									<div class="fn-desc min_height_132">
										<h4 class="fnd-title" v-if="featured.length_title>'6'">
											<router-link v-if="featured.ap_cat==1" :to="{name:'JurnalDetail',params:{slug:featured.ap_slug}}" v-tooltip="featured.ap_title">{{featured.ap_title.limitWord(6)}}</router-link>
											<router-link v-if="featured.ap_cat==2" :to="{name:'ArticleDetail',params:{slug:featured.ap_slug}}" v-tooltip="featured.ap_title">{{featured.ap_title.limitWord(6)}}</router-link>
										</h4>
										<h4 class="fnd-title" v-else>
											<router-link v-if="featured.ap_cat==1" :to="{name:'JurnalDetail',params:{slug:featured.ap_slug}}">{{ featured.ap_title }}</router-link>
											<router-link v-if="featured.ap_cat==2" :to="{name:'ArticleDetail',params:{slug:featured.ap_slug}}">{{ featured.ap_title }}</router-link>
										</h4>
										<h5 class="fnd-name" v-if="featured.length_name>'15'" data-toggle="tooltip" :title="featured.ap_author_name">
											<i class="icon-user21"></i>
											{{featured.ap_author_name.limitChar(15)}}
										</h5>
										<h5 class="fnd-name" v-else>
											<i class="icon-user21"></i>
											{{featured.ap_author_name}}
										</h5>
										<span class="fnd-date">
											<i class="icon-calendar21"></i>&nbsp;
											{{(featured.ap_publish_date).dates('format')}}
										</span>
									</div>
								</div>
							</div>
							<div v-for="(berita,k) in berita" :key="k" class="col-sm-6 col-lg-4 col-md-6">
								<div class="front-news">
									<div class="fn-img">
										<router-link v-if="berita.ap_cat==1" :to="{name:'JurnalDetail',params:{slug:berita.ap_slug}}">
											<img v-if="!isMobile" :src="uploader(berita.ap_photo,'360x239')" :alt="berita.ap_title" :title="berita.ap_title">
											<img v-else :src="uploader(berita.ap_photo,'330x219')" :alt="berita.ap_title" :title="berita.ap_title">
										</router-link>
										<router-link v-if="berita.ap_cat==2" :to="{name:'ArticleDetail',params:{slug:berita.ap_slug}}">
											<img v-if="!isMobile" :src="uploader(berita.ap_photo,'360x239')" :alt="berita.ap_title" :title="berita.ap_title">
											<img v-else :src="uploader(berita.ap_photo,'330x219')" :alt="berita.ap_title" :title="berita.ap_title">
										</router-link>
									</div>
									<div class="fn-desc min_height_132">
										<h4 class="fnd-title" v-if="berita.length_title>'6'">
											<router-link v-if="berita.ap_cat==1" :to="{name:'JurnalDetail',params:{slug:berita.ap_slug}}" v-tooltip="berita.ap_title">{{ berita.ap_title.limitWord(6) }}</router-link>
											<router-link v-if="berita.ap_cat==2" :to="{name:'ArticleDetail',params:{slug:berita.ap_slug}}" v-tooltip="berita.ap_title">{{ berita.ap_title.limitWord(6) }}</router-link>
										</h4>
										<h4 class="fnd-title" v-else>
											<router-link v-if="berita.ap_cat==1" :to="{name:'JurnalDetail',params:{slug:berita.ap_slug}}">{{ berita.ap_title }}</router-link>
											<router-link v-if="berita.ap_cat==2" :to="{name:'ArticleDetail',params:{slug:berita.ap_slug}}">{{ berita.ap_title }}</router-link>
										</h4>
										<h5 class="fnd-name" v-if="berita.length_name>'15'" data-toggle="tooltip" :title="berita.ap_author_name">
											<i class="icon-user21"></i>
											{{berita.ap_author_name.limitChar(15)}}
										</h5>
										<h5 class="fnd-name" v-else>
											<i class="icon-user21"></i>
											{{berita.ap_author_name}}
										</h5>
										<span class="fnd-date">
											<i class="icon-calendar21"></i>&nbsp;
											{{(berita.ap_publish_date).dates('format')}}
										</span>
									</div>
								</div>
							</div>
							<div class="col-12 text-center mt-3">
								<router-link class="btn btn-border_red" :to="{name:'Artikel'}">{{ web.btn_selengkapnya }}</router-link>
							</div>
						</div>
					</div>
				</section>

				<section class="section nobg nobottommargin notopmargin">
					<div class="container">
						<div class="heading-block center">
							<h3>{{ web.lbl_dm }}</h3>
						</div>
						<div class="row justify-content-center">
							<div class="col-sm-6 col-lg-4 col-md-6" v-for="(v4,k4) in dm" :key="k4">
								<div class="wrap_direct_mail">
									<router-link :to="{name:'DirectMailDetail',params:{slug:v4.mdm_slug}}">
										<VImg v-if="!isMobile" :src="uploader(v4.mdm_file,'400x533')" :alt="v4.mdm_title" :title="v4.mdm_title" />
										<VImg v-else :src="uploader(v4.mdm_file,'310x413')" :alt="v4.mdm_title" :title="v4.mdm_title" />
									</router-link>
									<div class="content_direct_mail"><p>{{ v4.mdm_title }}</p></div>
								</div>
							</div>
							<div class="col-12 text-center mt-4 mt-2-5">
								<router-link :to="{name:'DirectMail'}" class="btn btn-border_red">{{ web.btn_lihat_lainnya }}</router-link>
							</div>
						</div>
					</div>
				</section>
			</div>
		</section> 

		<div class="modal1 mfp-hide" id="detailPabrik">
			<div class="modal-dialog">
				<div class="modal-content">
					<div class="wrap_comp_logo">
						<img :src="assets('fo_images','circle_logo.png')" alt="cyrcle-logo" />
					</div>
					<div class="modal-header"></div>
					<div class="modal-body">
						<div class="container">
							<div class="row justify-content-center">
								<div class="col-lg-6">
									<div class="dp-img mb-3">
										<img class="image_pb" src="" alt="Foto Pabrik">
									</div>
								</div>
								<div class="col-lg-6">
									<div class="area-desc">
										<h3 class="ac-head company_name"></h3>
										<p class="ac-content"></p>
									</div>
								</div>
							</div>
							<div class="row">
								<div class="col-md-6">
									<div class="area-contact">
										<h4 class="ac-head">{{ web.lbl_kontak }}</h4>
										<ul class="ac-list">
											<li class="ac-item item_telpn">
												<a class="telpto" href="">
													<i class="icon-phone3"></i><span class="ac-phone"></span>
												</a>
											</li>
											<li class="ac-item item_email">
												<a class="mailto" href="">
													<i class="icon-mail"></i><span class="ac-email"></span>
												</a>
											</li>
										</ul>
									</div>
								</div>
								<div class="col-md-6">
									<div class="area-contact">
										<h4 class="ac-head">{{ web.lbl_alamat }}</h4>
										<address class="ac-content"></address>
										<a href="javascript:;" target="_blank" style="color:#fff;" class="link_map btn btn-primary mb-4">{{ web.btn_lihat_google }}</a>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- <div class="modal1 mfp-hide" id="warning">
			<div class="modal-dialog">
				<div class="modal-content">
					<div class="modal-header align-items-center">
						<VImg :src="assets('fo_images', 'logo.png')"/>
						<i class="icon-warning-sign text-danger"></i>
					</div>
					<div class="modal-body">
						<div class="row justify-content-center">
							<div class="col-lg-10 text-center">
								<p class="mb-3"><strong>Menanggapi informasi lowongan pekerjaan dan pemanggilan peserta untuk tes seleksi di PT Farmsco Feed Indonesia yang beredar</strong></p>
								<h3 class="text-danger mb-3">Kami sampaikan bahwa informasi tersebut merupakan PENIPUAN</h3>
								<p>Farmsco tidak pernah bekerjasama dengan travel agent/biro perjalanan manapun untuk melakukan proses rekrutmen dan tidak pernah meminta kandidat untuk mentransfer uang dengan alasan apapun, termasuk pembelian tiket pesawat selama proses perekrutan</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div> -->
  	</div>
</template>